<template>
  <div class="app">
    <a-card>
      <a-row>
        <a-col :span="24">
          <a-button-group>
            <a-button @click="activeModel=1" :type="activeModel==1?'primary':''">微信</a-button>
            <a-button @click="activeModel=2" :type="activeModel==2?'primary':''"> 千牛 </a-button>
            <a-button @click="activeModel=3" :type="activeModel==3?'primary':''"> GPT配置 </a-button>
            <a-button @click="activeModel=4" :type="activeModel==4?'primary':''"> 日志 </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </a-card>

    <a-card title="主程序" v-show="activeModel==1" style="margin-top: 10px">
      <a-row>
        <a-col :span="24">
          <a-button type="primary" v-if="!connected" @click="connectWebSocket">
            <a-icon type="play-circle" />启动主程序</a-button
          >
          <a-button v-else @click="disconnectWebSocket">
            <a-icon type="logout" /> 关闭主程序</a-button
          >
        </a-col>
      </a-row>
    </a-card>
    <a-card title="微信" v-show="activeModel==1" style="margin-top: 10px">
      <a-row>
        <a-col :span="24">
          <a-button
            type="primary"
            v-if="!config.wx.isWeixin"
            @click="sentSocket('startWeixin', '', true)"
          >
            <a-icon type="wechat" />启动微信
          </a-button>
          <a-button
            type="primary"
            v-else
            @click="sentSocket('closeWeixin', '', true)"
          >
            <a-icon />停止微信
          </a-button>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="千牛" v-show="activeModel==2" style="margin-top: 10px">
      <a-row>
        <a-col :span="24">
          <a-button
            type="primary"
            v-if="!config.wx.isQianniu"
            @click="sentSocket('startQianniu', '', true)"
          >
            <a-icon type="aliwangwang" />启动千牛
          </a-button>
          <a-button
            type="primary"
            v-else
            @click="sentSocket('closeQianniu', '', true)"
          >
            <a-icon />停止千牛
          </a-button>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="GPT"  v-show="activeModel==3" style="margin-top: 10px">
      <a-row>
        <a-col :span="24">
          <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- baseURL: "",
          url: "",
          timeout: 60,
          chatId: "",
          key: "", -->
            <a-form-model-item label="请求地址">
              <a-input v-model="config.gpt.baseURL" />
            </a-form-model-item>
            <a-form-model-item label="链接">
              <a-input v-model="config.gpt.url" />
            </a-form-model-item>
            <a-form-model-item label="超时">
              <a-input v-model="config.gpt.timeout" />
            </a-form-model-item>
            <a-form-model-item label="会话">
              <a-input v-model="config.gpt.chatId" />
            </a-form-model-item>
            <a-form-model-item label="key">
              <a-input v-model="config.gpt.key" />
            </a-form-model-item>
          </a-form-model>
           <a-button type="primary" @click="setGptConfig">
            <a-icon type="play-circle" />确认修改</a-button
          >
        </a-col>
      </a-row>
    </a-card>

    <a-card title="日志"  v-show="activeModel==4" style="margin-top: 10px">
      <a-row>
        <a-col :span="24">
          <a-textarea placeholder="日志内容" v-model="messages" :rows="10" />
        </a-col>
      </a-row>
    </a-card>
    <!-- <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="微信" style="margin: 0 10px">
        <a-space direction="vertical">
          <a-row>
            <a-col :span="24">
              <a-button type="primary" @click="connectWebSocket">
                启动主程序
              </a-button>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-button type="primary"> 启动微信 </a-button>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-button type="primary">启动千牛</a-button>
            </a-col>
          </a-row>
        </a-space>
      </a-tab-pane>
      <a-tab-pane key="2" tab="牵牛" force-render> 开发中... </a-tab-pane>
      <a-tab-pane key="3" tab="抖店" force-render> 开发中... </a-tab-pane>
      <a-tab-pane key="4" tab="日志">
        <a-row>
          <a-col :span="24">
            <a-textarea placeholder="textarea with clear icon" />
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      socket: null,
      connected: false,
      messages: "",
      aiboteLog: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      activeModel:1,
      config: {
        gpt: {
          baseURL: "",
          url: "",
          timeout: 60,
          chatId: "",
          key: "",
        },
        wx: {
          isWeixin: false,
        },
        qn: {
          isQianniu: false,
        },
      },
    };
  },
  onload() {},
  methods: {
    callback(key) {
      console.log(key);
    },
    ddMsg(message) {
      if (!message.startsWith("{") && typeof message == "string") {
        this.aiboteLog.value += message + "\n";
        return;
      }
      message = JSON.parse(message);
      if (message.type == "log") {
        this.aiboteLog.value += message.data + "\n";
      }
    },
    //发送socket消息
    sentSocket(cmd, data = "", updateConfig = false) {
      if (!this.socket) {
        this.$message.error("请先启动主程序");
        return;
      }
      let jsonMsg = {
        cmd: cmd,
        data: data,
      };
      let strMsg = JSON.stringify(jsonMsg);
      console.log("发送消息", strMsg);
      this.socket.send(strMsg); //发送数据到服务端
      if (updateConfig) {
        this.getAllConfig();
      }
    },
    connectWebSocket() {
      this.socket = new WebSocket("ws://localhost:8181");
      this.$message.info("开始连接");
      this.socket.onopen = () => {
        this.connected = true;
        console.log("WebSocket connected");
        this.$message.success("连接成功");
        this.getAllConfig();
      };

      this.socket.onmessage = (event) => {
        // console.log("收到消息", event);
        // const message = { id: this.messages.length, text: event.data };
        let json = JSON.parse(event.data);
        console.log("收到消息", json);
        if (json.type == "log") {
          if(this.messages.length>5000){
            this.messages=""
          }
          this.messages =
            (json.time ? "【" + json.time + "】" : "") +
            json.data +
            "\n" +
            this.messages;
        } else if (json.type == "allConfig") {
          this.config = json.data;
        }
      };
      this.socket.onerror = (event) => {
        console.log(event);
        this.$message.error("通讯失败");
      };

      this.socket.onclose = () => {
        this.connected = false;
        console.log("WebSocket disconnected");
      };
    },
    setGptConfig(){
      this.sentSocket("setGptConfig",this.config.gpt)
    },
    getAllConfig() {
      this.sentSocket("getAllConfig");
    },
    disconnectWebSocket() {
      this.$message.error("主程序关闭");
      this.socket.close();
    },
  },
};
</script>

<style>
.app {
  margin: 10px;
}
html, body{
  height: auto;
}
</style>
